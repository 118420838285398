import React, {  Suspense, lazy, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const HomeOne = lazy(() => import("./home/HomeOne"));
const HomeTwo = lazy(() => import("./home/HomeTwo"));
const HomeThree = lazy(() => import("./home/HomeThree"));
const About = lazy(() => import("./pages/About"));
const Services = lazy(() => import("./service/Services"));
const Petrochemicals = lazy(() => import("./service/Petrochemicals"));
const MetalSteel = lazy(() => import("./service/MetalSteel"));
const ServiceDetailsRightSidebar = lazy(() => import("./service/ServiceDetailsRightSidebar"));
const Projects = lazy(() => import("./project/Projects"));
const ProjectDetails = lazy(() => import("./project/ProjectDetails"));
const BlogLeftSidebar = lazy(() => import("./blog/BlogLeftSidebar"));
const BlogRightSidebar = lazy(() => import("./blog/BlogRightSidebar"));
const BlogDetailsLeftSidebar = lazy(() => import("./blog/BlogDetailsLeftSidebar"));
const BlogDetailsRightSidebar = lazy(() => import("./blog/BlogDetailsRightSidebar"));
const Contact = lazy(() => import("./pages/Contact"));
const NoMAtch = lazy(() => import("./pages/404"));

const App = () => {

  return (
    <Router>
        <Suspense fallback={<div />}>
            <Routes>
                <Route path={`${process.env.PUBLIC_URL}/`} element={<HomeOne />} />
                <Route path={`${process.env.PUBLIC_URL}/home`} element={<HomeOne />} />
                <Route path={`${process.env.PUBLIC_URL}/home-two`} element={<HomeTwo />} />
                <Route path={`${process.env.PUBLIC_URL}/home-three`} element={<HomeThree />} />
                <Route path={`${process.env.PUBLIC_URL}/about-us`} element={<About />} />
                <Route path={`${process.env.PUBLIC_URL}/services`} element={<Services />} />
                <Route path={`${process.env.PUBLIC_URL}/metal-steel`} element={<MetalSteel/>} />
                <Route path={`${process.env.PUBLIC_URL}/petrochemicals`} element={<Petrochemicals />} />
                <Route path={`${process.env.PUBLIC_URL}/service-details-right-sidebar`} element={<ServiceDetailsRightSidebar />} />
                <Route path={`${process.env.PUBLIC_URL}/projects`} element={<Projects />} />
                <Route path={`${process.env.PUBLIC_URL}/project-details`} element={<ProjectDetails />} />
                <Route path={`${process.env.PUBLIC_URL}/blog-left-sidebar`} element={<BlogLeftSidebar />} />
                <Route path={`${process.env.PUBLIC_URL}/blog-right-sidebar`} element={<BlogRightSidebar />} />
                <Route path={`${process.env.PUBLIC_URL}/blog-details-left-sidebar`} element={<BlogDetailsLeftSidebar />} />
                <Route path={`${process.env.PUBLIC_URL}/blog-details-right-sidebar`} element={<BlogDetailsRightSidebar />} />
                <Route path={`${process.env.PUBLIC_URL}/contact-us`} element={<Contact />} />
                <Route path = "*" element={<NoMAtch />} />
            </Routes>
        </Suspense>
      </Router>
  )
}

export default App;