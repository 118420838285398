import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, {useState, useEffect, Suspense} from "react";
import { createRoot } from 'react-dom/client'
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";
import App from "./app";
import Whatsapp from "./components/Whatsapp";
import { StateProvider } from './StateContext';

const isLocale = () => localStorage.getItem("locale");
const defaultLocale = (isLocale() !== null) ? isLocale() : (() => ((navigator.language === "tr-TR") || (navigator.language === "tr")) ? "tr" : "en");

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
<>
{/*<StateProvider>*/}
<Suspense fallback = "loading">
    <App />
    <Whatsapp />
    </Suspense>
{/*</StateProvider>*/}
</>
);

{/*function RootComponent() {
    const [sharedState, setSharedState] = useState(defaultLocale);

    useEffect(() => {
        localStorage.setItem("locale", sharedState);
      }, [sharedState]);
    
  console.log(defaultLocale);
    return (
      <StateProvider value={{ sharedState, setSharedState }}>
        <App />
        <Whatsapp />
      </StateProvider>
    );
  }*/}
  
  // Render the RootComponent inside the createRoot block
  //root.render(<RootComponent />);

serviceWorker.register();
