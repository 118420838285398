import React from "react";
import { FaWhatsapp } from "react-icons/fa";

export default function WhatsApp() {
  return (
    <div>
      <a
        style={{ color: "white" }}
        href="https://wa.me/905350322761"
        className="float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp className="mt-1 ml-1" />
      </a>
    </div>
  );
}